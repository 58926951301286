import {
  resetWithdrawPassword,
  setWithdrawPassword
} from '@/utils/user.js';
import ToastBox from "@/components/toast.vue";
export default {
  name: "AgainCashPassword",
  components: {
    ToastBox
  },
  data() {
    return {
      show: false,
      keyValue: "",
      showToast: false,
      msg: ""
    }
  },
  methods: {
    valFun(num) {
      const len = this.keyValue.length
      if (len >= num) {
        const arr = this.keyValue.split('')
        return arr[num - 1]
      } else {
        return ""
      }
    },
    nextFun() {
      const isSet = this.$route.path == '/againCashPassword'
      const first = isSet ? sessionStorage.getItem("setCashPassword") : sessionStorage.getItem("resetCashPassword");
      if (!first) {
        this.msg = "请从正确方式进入本页面";
        this.showToast = true;
      } else if (this.keyValue.length != 6) {
        return
      } else if (String(first) !== String(this.keyValue)) {
        this.msg = "两次密码设置不一致<br>请重新输入密码";
        this.showToast = true;
        return
      } else {
        let data = {
          memberId: this.$cookies.get('memberId'),
          password1: first,
          password2: this.keyValue
        };
        if (!isSet) {
          let info = sessionStorage.getItem("identityProofInfo")
          info = info ? JSON.parse(info) : ""
          if (!info) {
            return this.$toast("请从正确方式进入该页面")
          }
          data.phone = info.phone
          data.code = info.smsCode
        }
        const apiFun = isSet ? setWithdrawPassword : resetWithdrawPassword
        apiFun(data).then(res => {
          if (res && res.code == "0") {
            const text = isSet ? '设置' : '重置'
            this.$toast.success('提现身份密码已' + text + '成功')
            this.$router.push("/balanceCashOut")
          }
        })
      }
    },
    handleSure() {
      this.showToast = false
    }
  }
}