<template>
  <div class="set-cash-password">
    <p class="title">{{ $route.path == '/againCashPassword' ? '重复输入提现身份验证密码' : '再次输入提现身份验证密码' }}</p>
    <div class="input-box flex flex-between">
      <input :value="valFun(1)" type="text" readonly @click="show = true" />
      <input :value="valFun(2)" type="text" readonly @click="show = true" />
      <input :value="valFun(3)" type="text" readonly @click="show = true" />
      <input :value="valFun(4)" type="text" readonly @click="show = true" />
      <input :value="valFun(5)" type="text" readonly @click="show = true" />
      <input :value="valFun(6)" type="text" readonly @click="show = true" />
    </div>
    <template v-if="$route.path == '/againCashPassword'">
      <p class="placeholder">再次输入一致的6位数字密码</p>
      <p class="next" @click="nextFun">设置提现密码</p>
    </template>
    <template v-else>
      <p class="placeholder">再次输入6位数字提现身份密码</p>
      <p class="next" @click="nextFun">重置密码</p>
    </template> 
    <van-number-keyboard
      v-model="keyValue"
      :show="show"
      :maxlength="6"
      :extra-key="'00'"
      @blur="show = false"
    />
    <toast-box v-if="showToast" :show-toast="showToast" :msg="msg" @sure="handleSure"></toast-box>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>